<template>
  <div class="wrapper" id="payreg">
    <ctheader></ctheader>
    <div ref="payreg" class="payreg">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="商户名称">
          <el-input
            v-model="formInline.mer_name"
            placeholder="商户名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model="formInline.mer_mobile_phone"
            placeholder="手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add" size="small">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="getRowClass"
      >
        <el-table-column fixed prop="mer_no" label="商户编号">
        </el-table-column>
        <el-table-column prop="mer_name" label="商户名称"> </el-table-column>
        <el-table-column prop="mer_mobile_phone" label="手机号">
        </el-table-column>
        <el-table-column label="商户类型">
          <template slot-scope="scope">
            {{
              scope.row.mer_type == 1
                ? "个体商户"
                : scope.row.mer_type == 2
                ? "企业"
                : "个体工商户"
            }}
          </template>
        </el-table-column>
        <el-table-column label="商户种类">
          <template slot-scope="scope">
            {{ scope.row.mer_sort == 1 ? "普通类" : "其他" }}
          </template>
        </el-table-column>
        <el-table-column label="经营者姓名" prop="contact_name">
        </el-table-column>
        <el-table-column label="证件类型">
          <template slot-scope="scope">
            {{
              scope.row.certificate_type == "I"
                ? "身份证"
                : scope.row.certificate_type == "P"
                ? "护照"
                : scope.row.certificate_type == "G"
                ? "军官证"
                : "其他证件"
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">
              编辑
            </el-button>
            <el-button @click="detail(scope.row)" type="text" size="small">
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :start="formInline.start"
        :total="total"
        :page-length="formInline.length"
        :pageCallback="getPayRegList"
      ></pagination>
    </div>
  </div>
</template>

<script>
import ctheader from "../../components/ctheader";
import router from "../../router";
import * as URL from "../../modules/URLs";
import APIs from "@/modules/APIs";
import { apiPost } from "../../modules/utils";
import pagination from "../../components/pagination";

export default {
  components: { ctheader, pagination },
  data() {
    return {
      formInline: {
        action: "list.get",
        start: 0,
        length: 10,
      },
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.getPayRegList();
  },
  methods: {
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    add() {
      router.push(URL.PAYREG_Add);
    },
    query() {
      this.formInline.start = 0;
      this.getPayRegList();
    },
    getPayRegList(index = 0) {
      this.formInline.action = "list.get";
      this.formInline.start = index;
      let $this = this;
      apiPost({
        url: APIs.PAYMENT,
        data: $this.formInline,
        success(res) {
          if (res.code === "0") {
            $this.tableData = res.data;
            $this.total = res.total;
          } else {
            $this.tableData = [];
            $this.total = 0;
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    edit(val) {
      router.push(URL.PAYREG_Edit + "/" + val.id);
    },
    detail(val) {
      router.push(URL.PAYREG_Detail + "/" + val.id);
    },
  },
};
</script>

<style lang="less">
.payreg {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
  .el-select .el-input.is-focus .el-input__inner {
    border-color: rgb(37, 120, 115);
  }
  .el-input--small .el-input__inner:focus {
    border-color: #257873;
  }
  .el-button--primary {
    background: #257873;
    border-color: #257873;
  }
  .el-button--text {
    color: #257873;
  }
}
</style>
